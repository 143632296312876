/* 

*/


.scrollbox {
  position: relative;
  z-index: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  word-wrap: nowrap;
  background: #FFF no-repeat;
  background-image:
    -webkit-radial-gradient(0 50%, farthest-side, rgba(0,0,0,0.2), rgba(0,0,0,0)),
    -webkit-radial-gradient(100% 50%, farthest-side, rgba(0,0,0,0.2), rgba(0,0,0,0));
  background-image:
    -o-radial-gradient(0 50%, farthest-side, rgba(0,0,0,0.2), rgba(0,0,0,0)),
    -o-radial-gradient(100% 50%, farthest-side, rgba(0,0,0,0.2), rgba(0,0,0,0));
  background-image:
    radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.2), rgba(0,0,0,0));
  background-position: 0 0, 100% 0;
  background-size: 1rem 100%;
}

.scrollbox::before,
.scrollbox::after {
  content: '';
    position: relative;
    z-index: -1;
    display: block;
    min-width: 2rem;
    margin: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
}

.scrollbox::before {
  max-width: 2rem;
  background: -webkit-gradient(linear,left top, right top,from(#FFF),color-stop(50%, #FFF),to(rgba(255,255,255,0)));
  background: -webkit-linear-gradient(left,#FFF,#FFF 50%,rgba(255,255,255,0));
  background: -o-linear-gradient(left,#FFF,#FFF 50%,rgba(255,255,255,0));
  background: linear-gradient(to right,#FFF,#FFF 50%,rgba(255,255,255,0));
}

.scrollbox::after {
  max-width: 2rem;
  background: -webkit-gradient(linear,right top, left top,from(rgba(255,255,255,0)),color-stop(50%, #FFF),to(#FFF));
  background: -webkit-linear-gradient(right,rgba(255,255,255,0),#FFF 50%,#FFF);
  background: -o-linear-gradient(right,rgba(255,255,255,0),#FFF 50%,#FFF);
  background: linear-gradient(to left,rgba(255,255,255,0),#FFF 50%,#FFF);
} 