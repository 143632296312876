.name {
  font-weight: bold;
  text-align: center;
}
span.meta {
  color: #888888;
  size: 0.8em;
}
.NamedChord {
  display: flex;
  flex-direction: column;
  max-width: 8rem;
  min-width: 8rem;
}